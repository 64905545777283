export const config = {
  production: true,
  JOBE_API_URL: "https://api.jobe.test.unlikely.ai/api/v1",
  AUTH0_CLIENT_ID: "ibDFqsFplYUrgbLytgpi18O4lys0rTJr",
  AUTH0_DOMAIN: "auth.test.mili.ai",
  AUTH0_API_IDENTIFIER: "jobe-test",
  DATADOG_APPLICATION_ID: "5c6712d7-ac70-493b-853a-8e067df2464c",
  DATADOG_CLIENT_TOKEN: "pub6664db3fb0edd53198b78d1d517d4703",
  DATADOG_APP_VERSION: process.env.NX_DATADOG_APP_VERSION,
  DATADOG_ENVIRONMENT: "test",
  DARKLY_CLIENT_TOKEN: "609bfeb54a15770c83d293ef",
  DEEPGRAM_ASR_TOKEN: "dc852f8fd8d4809eeb5966929695f259cbfbfa4e",
  MIXPANEL_TOKEN: "2135d6ec9879a2ac7a46f37123cae2a3",
  GEONAMES_API_BASE_PATH: "https://secure.geonames.org/searchJSON",
  GEONAMES_USERNAME: "mili_chat_ui",
};
