export const lineHeights = {
  xs: "1rem", // 16px
  sm: "1.25rem", // 20px
  md: "1.5rem", // 24px
  lg: "1.75rem", // 28px
  xl: "1.875rem", // 30px
  xxl: "2rem", // 32px
  xxxl: "2.25rem", // 36px
  xxxxl: "2.5rem", // 40px
};
