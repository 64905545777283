import { ComponentProps } from "react";
import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import { fontSizes, lineHeights } from "@unlikelyai-magic/ui/variables";

type TextSize = {
  fontSize: string;
  lineHeight: string;
};

const textSizes: Record<HeadingProps["level"], TextSize> = {
  h1: {
    fontSize: fontSizes.xxxxl,
    lineHeight: lineHeights.xxxxl,
  },
  h2: {
    fontSize: fontSizes.xxxl,
    lineHeight: lineHeights.xxxl,
  },
  h3: {
    fontSize: fontSizes.xxl,
    lineHeight: lineHeights.xxl,
  },
  h4: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.xl,
  },
  h5: {
    fontSize: fontSizes.md,
    lineHeight: lineHeights.md,
  },
  h6: {
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.xs,
  },
};

export type HeadingProps = ComponentProps<"h1"> & {
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: string;
  textAlign?: "left" | "center" | "right";
};

export const Heading = styled(({ level, children, ...props }: HeadingProps) => {
  const Component = level;
  return <Component {...props}>{children}</Component>;
})`
  font-weight: 600;
  color: ${(props) => props.color || "currentColor"};
  margin: 0;
  ${({ level }): CSSObject => textSizes[level]};
  ${({ textAlign }): CSSObject => ({
    textAlign,
  })};
`;
